import Vue, {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '../src/assets/css/index.defer.min.css'
import './assets/app.css'
import en from './locales/en.json'
import ar from './locales/ar.json'
import {
    createI18n
} from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueCookies from 'vue3-cookies'
import "light-icons/dist/light-icon.css";
import {
    Tabs,
    Tab
} from 'vue3-tabs-component';

function loadLocaleMessages() {
    const locales = [{
        en: en
    }, {
        ar: ar
    }]
    const messages = {}
    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
});
const app = createApp(App);
app.config.globalProperties.$appUrl = 'https://api.jsky.rs4it.com' //'https://api.jsky.rs4it.com'
app.config.globalProperties.$saudicupAppUrl = 'https://frosiah.rs4it.com/jcsa_v2/saudicup/public'
app.config.globalProperties.$saudicupAdminUrl = 'https://admin.v2.saudicup.rs4it.com'
app.config.globalProperties.$saudicupUserUrl = 'https://user.v2.saudicup.rs4it.com'

app.use(router);
app.use(VueSweetalert2);
app.use(VueCookies);
app.use(VueAxios, axios);
app.use(i18n);
app.use(Vue3Toasity);
app.component('tabs', Tabs);
app.component('tab', Tab);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');